@font-face {
	font-family: myFont;
	src: url('https://chatbotva.dubaichamber.com/images/helveticaneue.ttf');
}

#chatbot-parent * {
	font-family: 'Noto Kufi Arabic', sans-serif !important;
}

/*
.welcom-message-wrappaer {
	animation: anime 3s cubic-bezier(0.16, 1, 0.3, 1) 1s infinite alternate !important;
} */

@-webkit-keyframes anime {
	0% {
		width: 60px;
		height: 60px;
		background: #f0f0f0;
		-webkit-box-shadow: 0 0 0 #cccccc, 0 0 0 #ffffff, 10px 10px 10px #cccccc inset, -10px -10px 10px #ffffff inset;
		        box-shadow: 0 0 0 #cccccc, 0 0 0 #ffffff, 10px 10px 10px #cccccc inset, -10px -10px 10px #ffffff inset;
	}
	25% {
		width: 60px;
		height: 60px;
		background: #f8f8f8;
		-webkit-box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
		        box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
	}
	50% {
		width: 60px;
		height: 240px;
		background: #f8f8f8;
		-webkit-box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
		        box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
	}
	100% {
		width: 480px;
		height: 240px;
		background: #fafafa;
		-webkit-box-shadow: 40px 40px 40px #cccccc, 0 0 0 #ffffff, 0 0 0 #cccccc inset, 2px 2px 2px #ffffff inset;
		        box-shadow: 40px 40px 40px #cccccc, 0 0 0 #ffffff, 0 0 0 #cccccc inset, 2px 2px 2px #ffffff inset;
	}
}

@keyframes anime {
	0% {
		width: 60px;
		height: 60px;
		background: #f0f0f0;
		-webkit-box-shadow: 0 0 0 #cccccc, 0 0 0 #ffffff, 10px 10px 10px #cccccc inset, -10px -10px 10px #ffffff inset;
		        box-shadow: 0 0 0 #cccccc, 0 0 0 #ffffff, 10px 10px 10px #cccccc inset, -10px -10px 10px #ffffff inset;
	}
	25% {
		width: 60px;
		height: 60px;
		background: #f8f8f8;
		-webkit-box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
		        box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
	}
	50% {
		width: 60px;
		height: 240px;
		background: #f8f8f8;
		-webkit-box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
		        box-shadow: 10px 10px 10px #cccccc, 10px 10px 10px #ffffff, 0 0 0 #cccccc inset, 0 0 0 #ffffff inset;
	}
	100% {
		width: 480px;
		height: 240px;
		background: #fafafa;
		-webkit-box-shadow: 40px 40px 40px #cccccc, 0 0 0 #ffffff, 0 0 0 #cccccc inset, 2px 2px 2px #ffffff inset;
		        box-shadow: 40px 40px 40px #cccccc, 0 0 0 #ffffff, 0 0 0 #cccccc inset, 2px 2px 2px #ffffff inset;
	}
}

#chatbot-parent .sc-chat-window {
	width: 70%;
	min-height: 85vh !important;
}

#chatbot-parent .sc-message-list {
	/* background-color: #ffffff !important; */
	/* background: #f0f0ee !important; */
	background: #ffff !important;
}

#chatbot-parent .sc-message--content.sent .sc-message--text {
	color: #78797f;
	font-size: 1.125em;
	font-weight: 400;
	background-color: #999ad8;
	max-width: 100%;
	word-wrap: break-word;
	border-radius: 15px 15px 15px 15px;
}

#chatbot-parent #chatbot-parent.sc-message--content.received .sc-message--text {
	font-size: 1.125em;
	font-weight: 400;
	background-color: inherit;
	margin-right: 40px;
	max-width: 100%;
	word-wrap: break-word;
	border-radius: 0px 15px 15px 15px;
	padding: 0px;
}

#chatbot-parent .chatBotTooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	width: 100%;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

/* Tooltip text */
#chatbot-parent .chatBotTooltip .chatBotTooltiptext {
	/* visibility: visible; */
	visibility: hidden;
	width: 210px;
	bottom: 100%;
	left: 0%;
	margin-left: -50px;
	/* width: 120px; */
	background-color: rgb(59, 59, 59);
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
#chatbot-parent .chatBotTooltip:hover .chatBotTooltiptext {
	visibility: visible;
}

#chatbot-parent .sc-message {
	margin: 0px !important;
	width: 100% !important;
	padding-bottom: 1px !important;
	/* max-width: 65% !important; */
}

#chatbot-parent .ant-space-align-center {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	width: 100% !important;
	gap: 45%;
}

#chatbot-parent .received {
	background-color: #f1f1fb !important;
	padding: 5px;
	padding-left: 13px;
	border-radius: 0px 15px 15px 15px;
	width: auto !important;
	margin-left: 3% !important;
	max-width: 600px;
}

#chatbot-parent .sent {
	/* background-color: #999ad8; */
	background-color: #0a3b5b;
	border-radius: 15px 0px 15px 15px !important;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	margin-left: auto;
	margin-right: 3% !important;
	max-width: 400px !important;
	padding: 5px;
	max-width: 600px !important;
	width: auto;
}

#chatbot-parent .sc-message--content {
	-webkit-box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
	        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
#chatbot-parent .sc-header {
	border-radius: 8px 8px 0px 0px;
}
#chatbot-parent .sc-chat-window {
	border-radius: 25px 25px 25px 25px;
}

#chatbot-parent .chat-card,
.me-card .ant-card-head-title {
	padding: 0px 0 !important;
}

#chatbot-parent .chat-card,
#chatbot-parent .me-card .ant-card-head {
	min-height: 10px !important;
	padding: 0 12px;
	font-size: 14px;
	border: 0px;
}

#chatbot-parent .chat-card,
#chatbot-parent .me-card .ant-card-body {
	padding-bottom: 0px !important;
}
#chatbot-parent .me-card .ant-card-head {
	color: #ffffff !important;
}
#chatbot-parent .chat-card,
#chatbot-parent .me-card {
	border: 0px !important;
	background-color: #f1f1fb !important;
}
#chatbot-parent .me-card {
	border-radius: 15px !important;
	/* background-color: #999ad8 !important; */
	background-color: #0a3b5b !important;
}

#chatbot-parent .sc-message--avatar {
	margin-right: 0px !important;
}

#chatbot-parent .me-card .ant-card-body .ant-typography {
	color: #ffffff !important;
}

#chatbot-parent .sc-header {
	background: #002a3a;
}

#chatbot-parent .sc-launcher.opened:before {
	-webkit-box-shadow: 0px 0px 400px 250px rgb(148 149 150 / 20%);
	        box-shadow: 0px 0px 400px 250px rgb(148 149 150 / 20%);
	background-color: #002a3a;
	border-radius: 50% 0 50% 50%;
}

#chatbot-parent .sc-launcher .sc-closed-icon {
	background-color: #002a3a;
	border-radius: 50%;
}

#chatbot-parent .sc-message--content.sent .sc-message--text {
	padding: 0px !important;
}

#chatbot-parent .sc-message--text {
	width: auto;
	float: left !important;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	margin-left: auto;
}

#chatbot-parent .sc-message-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	row-gap: 0.8em;
}

#chatbot-parent .sc-message--content.sent {
	-webkit-box-pack: end !important;
	    -ms-flex-pack: end !important;
	        justify-content: end !important;
}

#chatbot-parent .sc-message--content.sent .sc-message--avatar {
	display: block !important;
}

#chatbot-parent .sent .sc-message--avatar {
	background-image: url(https://thumbs.dreamstime.com/b/animation-portrait-arab-man-traditional-headdress-vector-illustration-isolated-white-background-animation-117460675.jpg) !important;
	margin-bottom: auto !important;
	margin-top: 4px !important;
}

#chatbot-parent .tick-date {
	text-align: end;
	color: #696969 !important;
}

#chatbot-parent .footer-text {
	font-size: 0.9em !important;
	color: #696969 !important;
	min-width: 200px;
}

#chatbot-parent .message-text {
	font-size: 14px;
}

#chatbot-parent .received .sc-message--avatar {
	margin-right: 10px;
	margin-bottom: auto;
	margin-top: 10px;
}

#chatbot-parent .text-align-left {
	text-align: end !important;
}

#chatbot-parent .ant-form-horizontal .ant-form-item-label {
	width: 100% !important;
	text-align: left !important;
}

#chatbot-parent .chat-input,
#chatbot-parent .PhoneInput {
	border: 1px solid #d9d9d9 !important;
	min-height: 40px;
	font-size: 14px !important;
	background: #fff !important;
}

#chatbot-parent .PhoneInput {
	padding: 0px 1em;
}

#chatbot-parent .PhoneInputInput {
	border: 0px !important;
}
#chatbot-parent .PhoneInputInput:focus {
	border: 0px !important;
	outline: none !important;
}

#chatbot-parent .chat-form {
	border: 1px solid #d4d4d7 !important;
	margin-left: 10% !important;
	max-width: 300px !important;
	border-radius: 15px !important;
	-webkit-box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
	        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

/* .avatar-visibility .received .sc-message--avatar {
	visibility: none;
} */

#chatbot-parent .date-selector .ant-select-selector {
	border-color: rgb(59, 61, 151);
	border-radius: 15px;
}

#chatbot-parent .ant-table-thead > tr > th {
	position: relative;
	background-color: #3b3d97 !important;
	color: #ffff !important;
}

#chatbot-parent .payment-buttons .ant-btn-round {
	background: rgb(255, 255, 255) !important;
	border: 1px solid #3b3d97 !important;
	color: #3b3d97 !important;
}

#chatbot-parent .button-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	width: 65%;
}

#chatbot-parent .outlined-button {
	background: rgb(255, 255, 255) !important;
	border: 1px !important;
	border-color: #002a3a !important;
	border-style: solid !important;
	color: #002a3a !important;
}

#chatbot-parent .rating {
	display: inline-block;
	width: 100%;
	margin-top: 40px;
	padding-top: 40px;
	text-align: center;
}

#chatbot-parent .like,
#chatbot-parent .dislike {
	display: inline-block;
	cursor: pointer;
	margin: 10px;
}

#chatbot-parent .dislike:hover,
#chatbot-parent .like:hover {
	color: #2ebdd1;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(1.1);
	    -ms-transform: scale(1.1);
	        transform: scale(1.1);
}

#chatbot-parent .active {
	color: #2ebdd1;
}

/* .received .sc-message--avatar {
	background-image: url(https://gm1.ggpht.com/PAACDjtXe0kSJtDQnyqzevJ7YxlqNg8WvVukKaC53mePisDIoGlDmHv2a6IXFeTDT6gLi5v2gRmsMRGGONSXZ3nQ5V-byBZ9KJDqcAoXV_lQSIXlGAmqViWE_IBlmYS5Tju43lh7gBguB75GcKnyfUbqUjIppiH17ZhRy_2JBRy4sRTC4YUg9CumPREi3X4DupAjF_dUDfxEhnfE365tWir7UP2cC7NCCds-s7B3NVD0cdVfL-a5HPKKgr0PFSN-YiKtHRfTIRgnGvHLs5g7gjCC1N8DAPc_DppUTsROWH7-Szfal59ReybvYbBwC0G__UYc1qL30S2X3Kn5yjobHmAx2HnNFU3h_iIZ93mrsxnXpUbmG_np2WbJ6kF_NWo9tLLoSs71cKdU1e_lJD57wdSUdExGZodY71xDHn7hV4vCGUwGwF9vCSvcwH-zpaxpBzS6w1NLmOqOumL0jN7frNS2vKx6IHgIlfHla42gblwluuf593ebNvRgzKgDHU8VvJGjwbYiDyEfi_ZU_9r8tdf_zh8-Vn0TzmsUSQ65WHQfF05x66WpcMH0iSxqPOaKq0oUrSNbM8uQiUIdMBofA6DBn6dPaJ7CXX9wgb14vCtD1If17zU2l0M-1wa6TUrHEAZJyxxyi8mx7yEufMnZjXlAHrbsDCrgVfajS1aZ5S7Qh5HeNqv6B-FPRASaeua18RfFiEnIVztKnT-sRy5C2KfusBtGb8s9anNc4FNrYP1zvJF82dP-uCHiTlNOwIsf0ExkCJc=s0-l75-ft-l75-ft) !important;
} */

#chatbot-parent .bot-icon .received .sc-message--avatar {
	background-image: url('https://chatbotva.dubaichamber.com/images/bot.jpeg') !important;
}

#chatbot-parent .agent-icon .received .sc-message--avatar {
	background-image: url('') !important;
}

#chatbot-parent .display-buttons {
	min-height: 50px;
	min-width: 200px;
	margin-left: 5%;
}

#chatbot-parent .button-options {
	background: rgb(255, 255, 255) !important;
	border: 1px solid #002a3a !important;
	color: #002a3a !important;
	-webkit-box-shadow: 0 4px 8px rgb(0 0 0 / 12%) !important;
	        box-shadow: 0 4px 8px rgb(0 0 0 / 12%) !important;
}

#chatbot-parent .buttons-wrapper .ant-btn:not([disabled]):hover {
	text-decoration: none;
	background: #002939 !important;
	color: #fff !important;
}

#chatbot-parent .button-options > span {
	font-weight: 500;
	font-size: 14px !important;
}

#chatbot-parent .buttons-wrapper .ant-btn-round {
	height: 40px !important;
}

#chatbot-parent .image-warpper {
	border-radius: 10px !important;
	-webkit-box-shadow: 0 9px 24px rgb(0 0 0 / 12%), 0 9px 24px rgb(0 0 0 / 12%);
	        box-shadow: 0 9px 24px rgb(0 0 0 / 12%), 0 9px 24px rgb(0 0 0 / 12%);
	border-radius: 10px;
	width: 80%;
	margin: auto !important;
	border: 1px solid #d4d4d7 !important;
}

#chatbot-parent .image-warpper .ant-card-bordered .ant-card-cover {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

#chatbot-parent .image-warpper .ant-card-cover img {
	border-radius: 13px 13px 0 0;
}

#chatbot-parent .buttons-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-column-gap: 1em;
	   -moz-column-gap: 1em;
	        column-gap: 1em;
	row-gap: 1em;
}

#chatbot-parent .ant-card-head-title {
	font-size: 12px !important;
}

#chatbot-parent .ant-typography {
	font-size: 14px !important;
}

#chatbot-parent .sc-header--team-name {
	font-size: 14px;
	padding: 1px;
	text-align: center;
}

#chatbot-parent .ant-form-item {
	font-size: 18px !important;
}

#chatbot-parent .ant-form-item-label {
	font-size: 18px !important;
}

#chatbot-parent .ant-form-item-required {
	font-size: 14px !important;
}

#chatbot-parent label {
	font-size: 14px !important;
}

#chatbot-parent .footer-text {
	font-size: 12px !important;
}

#chatbot-parent .calendar-card .ant-card-body {
	border: 1px solid #3b3d97;
	border-radius: 15px !important;
}

#chatbot-parent .calendar-card {
	border: 0px !important;
}

#chatbot-parent .ant-table-cell {
	font-size: 14px !important;
}

#chatbot-parent .button-background {
	background: #3b3d97 !important;
	color: #ffff !important;
}

#chatbot-parent .payment-header {
	min-width: 150px !important;
	min-height: 50px !important;
}

#chatbot-parent .intro-avatar .ant-avatar-lg {
	width: 100px !important;
	height: 100px !important;
}
#chatbot-parent .intro-avatar {
	row-gap: 3em !important;
}
#chatbot-parent .sc-header--img {
	width: 6% !important;
}

#chatbot-parent .ant-divider-horizontal.ant-divider-with-text::before,
#chatbot-parent .ant-divider-horizontal.ant-divider-with-text::after {
	top: 0 !important;
}

#chatbot-parent .ant-divider-inner-text {
	font-size: 12px;
}

#chatbot-parent .ant-typography {
	color: rgba(0, 0, 0, 0.85);
	overflow-wrap: break-word;
	text-align: center;
	width: 100%;
	font-weight: 500;
}

#chatbot-parent .flex-display-row {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
}

#chatbot-parent .welcome-message-wrapper {
	/* background: linear-gradient(-45deg, #dc4405, #012838, #b2b2b2);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite !important; */
	background-color: #002a39 !important;
}

@-webkit-keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#chatbot-parent .css-1di2tiy {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	margin: 0px;

	padding: 10px 20px;
	letter-spacing: 0.6px;
	line-height: 20px;

	overflow-wrap: break-word;
	-ms-flex-item-align: start;
	    align-self: flex-start;
}

#chatbot-parent .form-text {
	color: hsl(210deg 4% 40%);
}

/* .message-text .ant-form-item-control,
.message-text .ant-form-item-control-input,
.message-text .ant-col,
.message-text .ant-form-item-control-input-content,
.message-text input {
	height: 100% !important;
} */

#chatbot-parent .message-form .ant-form-item {
	margin-bottom: 0px;
}

#chatbot-parent .message-form .ant-input:focus,
#chatbot-parent .ant-input-focused {
	-webkit-box-shadow: unset !important;
	box-shadow: unset !important;
}

#chatbot-parent .message-form .ant-input:focus,
#chatbot-parent .ant-input-focused {
	background-color: #f4f7f9;
}

/* .mobile-input {
	min-width: 250px !important;
} */

#chatbot-parent .adm-space-item:first-child {
	-webkit-box-flex: 0 !important;
	    -ms-flex: 0 0 65% !important;
	        flex: 0 0 65% !important;
}

#chatbot-parent .adm-space-horizontal.adm-space-wrap {
	width: 100% !important;
}

#chatbot-parent .mobile-input-form #message {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

#chatbot-parent .input-button {
	border: 0px !important;
	height: 100% !important;
	margin-left: 2px !important;
	background-color: #ffff !important ;
}

#chatbot-parent .send-button .ant-btn[disabled],
#chatbot-parent .input-button .ant-btn[disabled] {
	background-color: #c0cef1 !important ;
}

#chatbot-parent .send-button {
	background-color: #c0cef1 !important ;
	/* min-width: 31px !important; */
}

#chatbot-parent .sc-user-input--button .ant-btn-circle {
	min-width: 31px !important;
}

#chatbot-parent .sc-user-input .ant-btn-circle {
	width: 40px !important;
	/* margin-right: -1px !important; */
}

#chatbot-parent .chatbava_dubaichamberFeedbackQuestion {
	margin-bottom: '3px';
	font-size: 16px !important;
	font-weight: 700 !important;
}

#chatbot-parent .chatbava_dubaichamberFeedbackQuestion {
	margin-bottom: '3px';
	font-size: 16px !important;
	font-weight: 700 !important;
}
#chatbot-parent .chatbava_dubaichamberFeedbackSubmitBtn {
}
#chatbot-parent .flex-space-between {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}

#chatbot-parent .emoji-render > p {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

#chatbot-parent .phone-error .ant-form-item-explain-error > div:nth-child(2) {
	display: none !important;
}

#chatbot-parent .right-top {
	border-radius: 14px 3px 14px 14px !important;
}

#chatbot-parent .left-top {
	border-radius: 3px 14px 14px 14px !important;
}

#chatbot-parent .receive-message-text > div > span {
	line-break: initial;
}

#chatbot-parent .send-message-text > div > span,
#chatbot-parent .receive-message-text > div > span {
	line-break: initial;
	font-size: 14px !important;
}

#chatbot-parent .emojione {
	width: 20px;
	margin: 0 5px;
}

#chatbot-parent .welcom-card {
	border-radius: 7px !important;
	width: 100% !important;
	background: #ffff;
	-webkit-box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
	        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
	height: 100%;
	padding: 2em 2em !important;
}

#chatbot-parent .welcom-card .ant-card-body {
	height: 100% !important;
}

#chatbot-parent .dc-logo {
	min-width: 250px !important;
}

#chatbot-parent .dc-logo > img {
	-o-object-fit: contain !important;
	   object-fit: contain !important;
}
#chatbot-parent .welcome-title-en {
	font-size: 18px !important;
	color: #0a3b5b !important;
}

#chatbot-parent .welcome-title-ar {
	font-size: 20px !important;
	color: #0a3b5b !important;
	margin-top: 0 !important;
}
#chatbot-parent .welcome-description-en {
	font-size: 1.2em !important;
}
#chatbot-parent .chatbava_dubaichamberFeedbackEnterUName {
	padding-left: 12px;
	padding-right: 12px;
	/* justify-self: center; */
	text-align: center;
	margin: 15px 0px;
	/* margin-top: 15px; */
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	/* height: 40px; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
#chatbot-parent .chatbava_dubaichamberFeedbackSubmitForm {
	padding-left: 12px;
	padding-right: 12px;
	/* justify-self: center; */
	text-align: center;
	margin: 15px 0px;
	/* margin-top: 15px; */
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	/* height: 40px; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

#chatbot-parent .welcome-description-ar {
	direction: ltr;
	text-align: right;
	font-size: 1.2em !important;
}

#chatbot-parent .welcom-card .themed-button {
	height: 50px !important;
	background: #dc4405 !important;
	border-color: #dc4405 !important;
	-webkit-box-shadow: 0 20px 40px 0 rgb(247 52 94 / 25%);
	        box-shadow: 0 20px 40px 0 rgb(247 52 94 / 25%);
	font-size: 1.1em !important;
}

@-webkit-keyframes slide {
	0% {
		-webkit-transform: translateX(-25%);
		        transform: translateX(-25%);
	}
	100% {
		-webkit-transform: translateX(25%);
		        transform: translateX(25%);
	}
}

@keyframes slide {
	0% {
		-webkit-transform: translateX(-25%);
		        transform: translateX(-25%);
	}
	100% {
		-webkit-transform: translateX(25%);
		        transform: translateX(25%);
	}
}

/* .welcom-card {
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 0.25em;
	box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	left: 50%;
	padding: 10vmin;
	position: fixed;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
} */

#chatbot-parent .welcome-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 100% !important;
}

#chatbot-parent .welcome-buttom-wrapper {
	width: 100% !important;
}

#chatbot-parent .area {
	background: #4e54c8;
	background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
	width: 100%;
}

#chatbot-parent .circles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none !important;
}

#chatbot-parent .circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgb(133 127 127 / 20%);
	-webkit-animation: animate 25s linear infinite;
	        animation: animate 25s linear infinite;
	bottom: -150px;
}

#chatbot-parent .circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}

#chatbot-parent .circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	-webkit-animation-duration: 12s;
	        animation-duration: 12s;
}

#chatbot-parent .circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	-webkit-animation-delay: 4s;
	        animation-delay: 4s;
}

#chatbot-parent .circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
	-webkit-animation-duration: 18s;
	        animation-duration: 18s;
}

#chatbot-parent .circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}

#chatbot-parent .circles li:nth-child(6) {
	left: 75%;
	width: 110px;
	height: 110px;
	-webkit-animation-delay: 3s;
	        animation-delay: 3s;
}

#chatbot-parent .circles li:nth-child(7) {
	left: 35%;
	width: 150px;
	height: 150px;
	-webkit-animation-delay: 7s;
	        animation-delay: 7s;
}

#chatbot-parent .circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	-webkit-animation-delay: 15s;
	        animation-delay: 15s;
	-webkit-animation-duration: 45s;
	        animation-duration: 45s;
}

#chatbot-parent .circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	-webkit-animation-duration: 35s;
	        animation-duration: 35s;
}

#chatbot-parent .circles li:nth-child(10) {
	left: 85%;
	width: 150px;
	height: 150px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
	-webkit-animation-duration: 11s;
	        animation-duration: 11s;
}

@-webkit-keyframes animate {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		-webkit-transform: translateY(-1000px) rotate(720deg);
		        transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

@keyframes animate {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		-webkit-transform: translateY(-1000px) rotate(720deg);
		        transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

/*
 *  STYLE 3
 */

#chatbot-parent #styledc-3::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}

#chatbot-parent #styledc-3::-webkit-scrollbar {
	width: 1px;
	background-color: #f5f5f5;
}

#chatbot-parent #styledc-3::-webkit-scrollbar-thumb {
	background-color: #dcdcdc;
}

#chatbot-parent .ant-popover-inner {
	border-radius: 10px !important;
}

#chatbot-parent .row-wrapper {
	height: 102% !important;
}

#chatbot-parent .chat-content-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

/* .sidebar-wrapper .ant-collapse {
	padding: 2em;
} */

#chatbot-parent .sc-user-input--button .anticon-send {
	text-align: center;
	color: #fff;
	margin-left: 5px;
}

#chatbot-parent .sidebar-wrapper {
	background-color: #002a39 !important;
	max-height: 100% !important;
	/* max-width: 30.333333% !important; */
	border-radius: 14px !important;
	height: 100% !important;
	-webkit-box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
		rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
	        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
		rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

#chatbot-parent .sidebar-wrapper .ant-collapse {
	border: 0 !important ;
	background-color: #002a39 !important;
}

#chatbot-parent .sidebar-wrapper .ant-collapse-content > .ant-collapse-content-box {
	color: #ffff !important;
}

#chatbot-parent .sidebar-wrapper .ant-collapse-content-box {
	background-color: #002a39 !important;
	white-space: pre-wrap !important;
}

#chatbot-parent .padding-sidebar {
	padding: 0.5em 1.5em 1.5em 1.5em !important;
	max-height: 100% !important;
	overflow: hidden !important;
	height: 91% !important;
	border-radius: 20px;
}

#chatbot-parent .sidebar-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	color: #f2f2f2 !important;
}

#chatbot-parent .welcome-screen-wrapper {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	padding: 0 !important;
	background: #fff;
	width: 75%;
	height: 60%;
	border-radius: 14px;
}

#chatbot-parent .welcome-right-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 50%;
	        flex: 1 0 50%;
	/* background-color: #A4343a; */
	/* background-image: linear-gradient(315deg, #f5df2e 0%, #f07654 74%); */
	/* background-image: linear-gradient(315deg, #ffae4b 0%, #fd8c00 74%); */
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-ms-flex-pack: distribute;
	    justify-content: space-around;
	border-radius: 14px 14px 14px 14px;
	padding: 1em 1em;
}

#chatbot-parent .welcome-left-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 50%;
	        flex: 1 0 50%;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

#chatbot-parent .right-border-ar {
	border-radius: 14px 0 0 14px !important;
}

#chatbot-parent .left-border-ar {
	border-radius: 14px 0 0 14px !important;
}

#chatbot-parent .welcome-header {
	/* padding: 20px; */
	font-size: 30px !important;
	text-align: center;
	color: #a4343a !important;
	text-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px,
		rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
}
#chatbot-parent .service_down_msg_one {
	/* padding: 20px; */
	font-size: 18px !important;
	text-align: center;
	color: #000000 !important;
	text-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px,
		rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
}
#chatbot-parent .service_down_msg_two {
	/* padding: 20px; */
	font-size: 16px !important;

	color: #3f3f3f !important;
	text-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px,
		rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
}

#chatbot-parent .welcome-button {
	width: 25% !important;
	background-color: #002a39 !important;
	color: #fff !important;
	border: 0 !important;
	-webkit-box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px,
		rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
	        box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px,
		rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
}

#chatbot-parent .welcome-from-wrapper {
	width: 100%;
	height: 100%;
	background: #fff;
}

#chatbot-parent .sidebar-wrapper .ant-collapse-item-active .ant-collapse-header {
	background-color: #6588de !important ;
	border-radius: 14px;
	margin: 0.8em 0 !important;
}

#chatbot-parent .welcome-from-wrapper {
	background-image: url('https://chatbotva.dubaichamber.com/images/green.svg'),
		url('https://chatbotva.dubaichamber.com/images/orange.svg'),
		url('https://chatbotva.dubaichamber.com/images/leaf-left.svg'),
		url('https://chatbotva.dubaichamber.com/images/leaf-right.svg');
	background-repeat: no-repeat;
	background-size: 100%, 100%, auto, auto;
	background-position: bottom, bottom, center left, top right;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* align-items: center; */
	padding: 10em 8em;
}

#chatbot-parent .welcome-from-wrapper .item-1 {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 50%;
	        flex: 1 0 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* align-items: center; */
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	text-align: left;
}

#chatbot-parent .welcome-from-wrapper .item-2 {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 50%;
	        flex: 1 0 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 2em;
}

#chatbot-parent .item-1 > h1 {
	font-size: 5em;
	color: #1d2226;
	font-family: 'Roboto', sans-serif !important;
}

#chatbot-parent .item-1 > span {
	color: #646c70;
	font-family: 'Roboto', sans-serif !important;
	font-size: 1.5em;
}

#chatbot-parent .chat-input {
	-webkit-box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px,
		rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
	        box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px,
		rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
}

#chatbot-parent .welcome-wrapper-main {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	row-gap: 2em;
	height: calc(100%);
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

#chatbot-parent .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background-color: #a4343a !important;
	border-color: #a4343a !important;
	/* background-image: linear-gradient(315deg, #ffae4b 0%, #fd8c00 74%) !important; */
}

#chatbot-parent .ant-radio-button-wrapper:first-child {
	border-radius: 14px 0 0 14px !important;
}

#chatbot-parent .ant-radio-button-wrapper:last-child {
	border-radius: 0 14px 14px 0 !important;
}

#chatbot-parent .sidebar-wrapper .ant-collapse-content-active {
	border-top: 0 !important;
}

#chatbot-parent .sub-header {
	color: #646c70;
	font-family: 'Roboto', sans-serif !important;
	font-size: 0.8em;
	padding: 1px 1px;
}

#chatbot-parent .sub-header-wrapper .ant-card-body {
	padding: 1px 10px !important;
	text-align: center !important;
}

#chatbot-parent .sidebar-title {
	background-color: #6688de !important;
	color: #fff;
	padding: 0.8em;
	border-radius: 14px 14px 0 0 !important;
	text-align: center;
}

#chatbot-parent .sidebar-content {
	padding: 1em;
	height: 83% !important;
	overflow: scroll;
}

#chatbot-parent .sidebar-content > div:first-child {
	padding: 0 0.7em 0.7em 0.7em !important;
	scrollbar-width: thin;
}

#chatbot-parent .sidebar-subtitle {
	text-align: center;
	padding: 0.7em 0.7em;
	color: #fff;
	font-weight: bold;
	font-size: 1.1em;
}

#chatbot-parent .sc-message-list > div:first-child {
	padding: 1em;
	row-gap: 1em !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

#chatbot-parent .thar-four {
	color: #fff;
	cursor: pointer;
	display: block;
	position: relative;
	border: 2px solid #f7ca18;
	-webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
	-o-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
#chatbot-parent .thar-four:hover {
	color: #000 !important;
	background-color: transparent;
	text-shadow: nfour;
}
#chatbot-parent .thar-four:hover:before {
	right: 0%;
	left: auto;
	width: 100%;
}
#chatbot-parent .thar-four:before {
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 0px;
	z-index: -1;
	content: '';
	color: #000 !important;
	background: #f7ca18;
	-webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
	-o-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

/* .welcome-from-wrapper {
	background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
	position: absolute;
	top: -198px;
	width: 6400px;
	height: 198px;
	animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
	transform: translate3d(0, 0, 0);
}

.welcome-from-wrapper:nth-of-type(2) {
	top: -175px;
	animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
	opacity: 1;
} */

@-webkit-keyframes wave {
	0% {
		margin-left: 0;
	}
	100% {
		margin-left: -1600px;
	}
}

@keyframes wave {
	0% {
		margin-left: 0;
	}
	100% {
		margin-left: -1600px;
	}
}

@-webkit-keyframes swell {
	0%,
	100% {
		-webkit-transform: translate3d(0, -25px, 0);
		        transform: translate3d(0, -25px, 0);
	}
	50% {
		-webkit-transform: translate3d(0, 5px, 0);
		        transform: translate3d(0, 5px, 0);
	}
}

@keyframes swell {
	0%,
	100% {
		-webkit-transform: translate3d(0, -25px, 0);
		        transform: translate3d(0, -25px, 0);
	}
	50% {
		-webkit-transform: translate3d(0, 5px, 0);
		        transform: translate3d(0, 5px, 0);
	}
}

#chatbot-parent .mobile-dots {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	font-size: 24px !important;
	margin-left: 8px !important;
}

#chatbot-parent .mobile-modal .ant-modal-body {
	height: 100vh !important;
	padding: 0 !important;
}

#chatbot-parent .sidebar-subtitle-mobile {
	color: #fff;
	text-align: center;
	padding: 1em !important;
}

#chatbot-parent .mobile-modal .ant-modal-footer {
	display: none !important;
}
#chatbot-parent .mobile-modal .ant-modal-header {
	height: 8vh !important;
}

#chatbot-parent .mobile-modal .ant-modal-body {
	height: 92vh !important;
	max-height: 92vh !important;
	overflow: scroll !important;
}

#chatbot-parent .mobile-modal .mobile-modal .ant-modal-body {
	height: 90vh !important;
	padding: 0 !important;
	overflow: scroll !important;
}

@media (max-width: 767px) {
	#chatbot-parent .ant-modal-centered .ant-modal {
		max-width: calc(100vw - 0px) !important;
		margin: 0px !important;
		max-height: 100vh !important;
	}
}

#chatbot-parent .mobile-modal .ant-collapse-content-box {
	white-space: pre-wrap !important;
}

#chatbot-parent .arabic-sider .ant-collapse > .ant-collapse-item > .ant-collapse-header,
#chatbot-parent .arabic-modal .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: reverse !important;
	    -ms-flex-direction: row-reverse !important;
	        flex-direction: row-reverse !important;
}

#chatbot-parent .arabic-sider .anticon,
#chatbot-parent .arabic-modal .anticon {
	-ms-flex-item-align: center !important;
	    align-self: center !important;
	-webkit-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	        transform: rotate(180deg);
}

#chatbot-parent .arabic-modal .ant-modal-content {
	direction: rtl !important;
	text-align: right !important;
}

#chatbot-parent .arabic-modal .ant-modal-close {
	right: 85% !important;
}

#chatbot-parent .links-content > a,
#chatbot-parent .text-message-recieve > a {
	max-width: 100% !important;
	line-break: anywhere !important;
}

#chatbot-parent .text-message-recieve {
	white-space: pre-wrap;
	line-break: strict;
	font-size: 14px !important;
	text-align: initial !important;
}

#chatbot-parent .receive-message-title {
	text-align: initial !important;
}

#chatbot-parent .header-button {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	width: 20px !important;
	height: 20px !important;
	min-width: 20px !important;
	margin: 0 0.3em 0 0.3em !important;
	background: #a4343a !important;
	border: 0 !important;
}

#chatbot-parent .sc-header--close-dots .ant-tooltip-disabled-compatible-wrapper {
	border-radius: 50% !important;
}

#chatbot-parent .slick-slider {
	width: 95% !important;
}

#chatbot-parent .minimize {
	-ms-flex-item-align: start !important;
	    align-self: flex-start !important;
	margin: 10px !important;
	position: absolute !important;
	margin-left: 96% !important;
	background: #002939 !important;
	color: #fff !important;
}

#chatbot-parent .slick-slide > div {
	padding: 10px;
}

#chatbot-parent .slick-prev:before,
#chatbot-parent .slick-next:before {
	color: rgb(0 0 0 / 85%) !important;
	font-size: 30px !important;
}

#chatbot-parent .slick-slider .image-warpper {
	-webkit-box-shadow: none !important;
	        box-shadow: none !important;
}

#chatbot-parent .text-message-recieve ol,
#chatbot-parent .text-message-recieve ul {
	margin-top: 0.6em !important;
	margin-bottom: 1em;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	row-gap: 0.8em;
}

#chatbot-parent a {
	direction: ltr !important;
}

#chatbot-parent .footer-time {
	font-size: 10px !important;
}

#chatbot-parent .anticon-send,
#chatbot-parent .anticon-audio,
#chatbot-parent .anticon-like,
#chatbot-parent .anticon-dislike {
	font-size: 20px !important;
}

#chatbot-parent .text-message-recieve > span > ul > li {
	list-style: disc !important;
	text-align: initial !important;
	font-size: 14px !important;
}

#chatbot-parent .text-message-recieve ul,
#chatbot-parent .links-content > ul,
#chatbot-parent .links-content ul {
	padding: revert !important;
}

#chatbot-parent .sc-message ul li {
	list-style: disc !important;
	text-align: initial !important;
	font-size: 14px !important;
}

#chatbot-parent .links-content > ul > li,
#chatbot-parent .links-content ul li {
	list-style: disc !important;
	text-align: initial !important;
	font-size: 14px !important;
}

#chatbot-parent .links-content span,
#chatbot-parent .sc-message span,
#chatbot-parent .links-content > span,
#chatbot-parent .sc-message > span {
	font-size: 14px !important;
}

#chatbot-parent .anticon-like,
#chatbot-parent .anticon-dislike {
	font-size: 50px !important;
}

#chatbot-parent .feedback-card svg {
	font-size: 40px !important;
}

#chatbot-parent .service-list {
	width: 75% !important;
	border-radius: 10px !important;
	margin: 0 0 0 5% !important;
	-webkit-box-shadow: 0 4px 8px rgb(0 0 0 / 12%) !important;
	        box-shadow: 0 4px 8px rgb(0 0 0 / 12%) !important;
}

#chatbot-parent .service-list .ant-list-split .ant-list-item:hover {
	border-bottom: 0px !important;
	border-radius: 5px !important;
	background: #a4343a !important;
	color: #fff !important;
	padding: 12px 5px !important;
}

#chatbot-parent .service-list > .ant-collapse-item > .ant-collapse-header {
	background-color: #e3f4fc !important;
	color: #000 !important;
}

#chatbot-parent .service-list .ant-list-item {
	color: #002a3a !important;
}

#chatbot-parent .service-list .ant-list-split .ant-list-item {
	border-color: #a4343a !important;
}

#chatbot-parent .ant-popover {
	position: fixed !important;
}

#chatbot-parent .service-list .ant-list-item {
	cursor: pointer !important;
}

/* .service-list .ant-collapse-content{
	border-bottom: 1px solid #52585d !important;
} */

#chatbot-parent .service-list .ant-collapse > .ant-collapse-item {
	border-bottom: 1px solid #52585d;
}

#chatbot-parent .arabic-mode .service-list .ant-collapse-arrow {
	-webkit-transform: rotate(180deg) !important;
	    -ms-transform: rotate(180deg) !important;
	        transform: rotate(180deg) !important;
	margin-left: 10px !important;
}

#chatbot-parent .arabic-mode .ant-list-item-action .anticon-right {
	-webkit-transform: rotate(180deg) !important;
	    -ms-transform: rotate(180deg) !important;
	        transform: rotate(180deg) !important;
}

#chatbot-parent .service-list .ant-collapse-item:first-child .ant-collapse-header {
	border-radius: 10px 10px 0 0 !important;
}

#chatbot-parent .service-list .ant-collapse-item:last-child .ant-collapse-header {
	border-radius: 0 0 10px 10px !important;
}

#chatbot-parent .service-list .ant-collapse-item-active:last-child .ant-collapse-header {
	border-radius: 0 !important;
}

#chatbot-parent .service-list .ant-collapse-item:last-child {
	border-bottom: 0px !important;
}

#chatbot-parent .service-list .ant-collapse-item:last-child > .ant-collapse-content {
	border-radius: 0 0 10px 10px !important;
}

#chatbot-parent #highlighter {
	color: #1890ff !important;
	background: #e6f7ff !important;
	border-color: #91d5ff !important;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	margin: 0 8px 0 0;
	color: #000000d9;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	        font-feature-settings: 'tnum';
	display: inline-block;
	height: auto;
	padding: 0 7px;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	background: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	opacity: 1;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

#chatbot-parent .logo-align-center {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-pack: distribute !important;
	    justify-content: space-around !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	/* border-radius:5px 0 0 5px; */
	padding: 0.2em !important;
}

#chatbot-parent #highlighter-1,
#chatbot-parent .highlighter-1 {
	border: 1px solid;
	padding: 0 0.2em !important;
	border-radius: 5px;
	color: #a4343a !important;
	border-color: #a4343a !important;
	cursor: pointer;
}

#chatbot-parent #highlighter-1:hover,
#chatbot-parent .highlighter-1:hover {
	background-color: #a4343a !important;
	color: white !important;
}

#chatbot-parent .highlighter-1 {
	border: 1px solid;
	padding: 0 0.2em !important;
	border-radius: 5px;
	color: #a4343a !important;
	border-color: #a4343a !important;
	cursor: pointer;
}

#chatbot-parent .highlighter-1:hover {
	background-color: #a4343a !important;
	color: white !important;
}

#chatbot-parent .active .send-button {
	background-color: #a4343a !important;
}

#chatbot-parent .ant-modal-wrap {
	z-index: 9999999999 !important;
}

#chatbot-parent .input-button .ant-upload-list {
	display: none !important;
}

#chatbot-parent .upload-file-icon,
#chatbot-parent .upload-file-icon > svg {
	font-size: 40px !important;
}

#chatbot-parent .align-button {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
}

#chatbot-parent .ant-form-horizontal .ant-form-item-control {
	-webkit-box-flex: 1 !important;
	    -ms-flex: 1 0 100% !important;
	        flex: 1 0 100% !important;
}
