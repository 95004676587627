#chatbot-parent .welcome-from-wrapper-mobile {
	background-image: url('https://chatbotva.dubaichamber.com/images/green.svg'),
		url('https://chatbotva.dubaichamber.com/images/orange.svg'),
		url('https://chatbotva.dubaichamber.com/images/leaf-left.svg'),
		url('https://chatbotva.dubaichamber.com/images/leaf-right.svg');
	background-repeat: no-repeat;
	background-size: 100%, 100%, auto, auto;
	background-position: bottom, bottom, center left, top right;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	padding: 4em;
	width: 100%;
	height: 100%;
	background-color: #fff;
	row-gap: 2em;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

#chatbot-parent .item-1-mobile > h1 {
	font-size: 2em;
	color: #1d2226;
	font-family: 'Roboto', sans-serif !important;
}

#chatbot-parent .item-1-mobile > span {
	color: #646c70;
	font-family: 'Roboto', sans-serif !important;
	font-size: 1em;
}

.item-2-mobile {
}
