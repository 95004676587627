#chatbot-parent .sc-header {
	background: #4e8cff;
	/* min-height: 75px; */
	border-top-left-radius: 9px;
	color: white;
	padding: 1px;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	position: relative;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

#chatbot-parent .sc-header--img {
	border-radius: 50%;
	-ms-flex-item-align: center;
	    align-self: center;
	padding: 10px;
}

#chatbot-parent .sc-header--team-name {
	-ms-flex-item-align: center;
	    align-self: center;
	padding: 10px;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	border-radius: 5px;
}

#chatbot-parent .sc-header--close-button {
	width: 40px;
	-ms-flex-item-align: center;
	    align-self: center;
	height: 40px;
	margin-right: 10px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	cursor: pointer;
	border-radius: 5px;
}

#chatbot-parent .sc-header--close-dots {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	margin: 5px;
}

.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

#chatbot-parent .sc-header--close-button:hover {
	background: #4882ed;
}

#chatbot-parent .sc-header--close-button img {
	width: 100%;
	height: 100%;
	padding: 13px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}

@media (max-width: 450px) {
	#chatbot-parent .sc-header {
		border-radius: 0px;
	}
}
