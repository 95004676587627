#chatbot-parent .sc-launcher {
	width: 60px;
	height: 60px;
	background-color: #4e8cff;
	background-position: center;
	background-repeat: no-repeat;
	position: fixed;
	right: 25px;
	bottom: 25px;
	border-radius: 50%;
	-webkit-box-shadow: none;
	        box-shadow: none;
	-webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
	transition: -webkit-box-shadow 0.2s ease-in-out;
	-o-transition: box-shadow 0.2s ease-in-out;
	transition: box-shadow 0.2s ease-in-out;
	transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	max-width: 110px;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

#chatbot-parent .head-1 {
	font-size: 14px !important;
	font-weight: 700;
	color: #a4343a;
}

#chatbot-parent .head-2 {
	font-size: 14px !important;
	font-weight: 700;
	color: #002a3a;
}

#chatbot-parent .text-align-center {
	text-align: center;
}

#chatbot-parent .sc-launcher:before {
	content: '';
	position: relative;
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	-webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
	transition: -webkit-box-shadow 0.2s ease-in-out;
	-o-transition: box-shadow 0.2s ease-in-out;
	transition: box-shadow 0.2s ease-in-out;
	transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
}

#chatbot-parent .chatBotTooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
#chatbot-parent .chatBotTooltip .chatBotTooltiptext {
	/* visibility: visible; */
	visibility: hidden;
	width: 210px;
	bottom: 100%;
	left: 0%;
	margin-left: -50px;
	/* width: 120px; */
	background-color: rgb(59, 59, 59);
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
#chatbot-parent .chatBotTooltip:hover .chatBotTooltiptext {
	visibility: visible;
}

#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}
.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

#chatbot-parent .sc-launcher .sc-open-icon,
#chatbot-parent .sc-launcher .sc-closed-icon {
	width: 60px;
	height: 60px;
	position: fixed;
	right: 25px;
	bottom: 25px;
	-webkit-transition: opacity 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
	transition: opacity 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
	-o-transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
	transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
	transition: opacity 100ms ease-in-out, transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
}

#chatbot-parent .sc-launcher .sc-closed-icon {
	-webkit-transition: opacity 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
	transition: opacity 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
	-o-transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
	transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
	transition: opacity 100ms ease-in-out, transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
	width: 60px;
	height: 60px;
}

#chatbot-parent .sc-launcher .sc-open-icon {
	padding: 20px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	opacity: 0;
}

#chatbot-parent .sc-launcher.opened .sc-open-icon {
	-webkit-transform: rotate(-90deg);
	    -ms-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	opacity: 1;
}

#chatbot-parent .sc-launcher.opened .sc-closed-icon {
	-webkit-transform: rotate(-90deg);
	    -ms-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	opacity: 0;
}

#chatbot-parent .sc-launcher.opened:before {
	-webkit-box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
	        box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

#chatbot-parent .sc-launcher:hover {
	-webkit-box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
	        box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
}

#chatbot-parent .sc-new-messages-count {
	position: absolute;
	top: -3px;
	left: 41px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	background: #ff4646;
	color: white;
	text-align: center;
	margin: auto;
	font-size: 12px;
	font-weight: 500;
	-webkit-box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.3);
	        box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.3);
}
