#chatbot-parent .sc-emoji-picker {
	overflow: auto;
	width: 100%;
	max-height: calc(100% - 40px);
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	padding: 15px;
}

#chatbot-parent .sc-emoji-picker--category {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

#chatbot-parent .sc-emoji-picker--category-title {
	min-width: 100%;
	color: #b8c3ca;
	font-weight: 200;
	font-size: 13px;
	margin: 5px;
	letter-spacing: 1px;
}

#chatbot-parent .sc-emoji-picker--emoji {
	margin: 5px;
	width: 30px;
	line-height: 30px;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	font-size: 28px;
	transition: transform 60ms ease-out, -webkit-transform 60ms ease-out;
	-webkit-transition-delay: 60ms;
	     -o-transition-delay: 60ms;
	        transition-delay: 60ms;
}

#chatbot-parent .sc-emoji-picker--emoji:hover {
	-webkit-transform: scale(1.4);
	    -ms-transform: scale(1.4);
	        transform: scale(1.4);
}
