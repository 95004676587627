#chatbot-parent .sc-popup-window {
	position: relative;
	width: 150px;
}

#chatbot-parent .sc-popup-window--cointainer {
	position: absolute;
	bottom: 20px;
	right: 100px;
	width: 330px;
	max-height: 260px;
	height: 260px;
	-webkit-box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
	        box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
	background: white;
	border-radius: 10px;
	outline: none;
	-webkit-transition: 0.2s ease-in-out;
	-o-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
	z-index: 1;
	padding: 0px 5px 5px 5px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}

#chatbot-parent .sc-popup-window--cointainer.closed {
	opacity: 0;
	visibility: hidden;
	bottom: 14px;
}

#chatbot-parent .sc-popup-window--cointainer:after {
	content: '';
	width: 14px;
	height: 14px;
	background: white;
	position: absolute;
	z-index: -1;
	bottom: -6px;
	right: 28px;
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	border-radius: 2px;
}

#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

#chatbot-parent .sc-popup-window--search {
	width: 290px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	margin: auto;
	display: block;
	border-width: 0px 0px 1px 0px;
	color: #565867;
	padding-left: 25px;
	height: 40px;
	font-size: 14px;
	background-image: url(https://js.intercomcdn.com/images/search@2x.32fca88e.png);
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: 0 12px;
	outline: none;
}

#chatbot-parent .sc-popup-window--search::-webkit-input-placeholder {
	color: #c1c7cd;
}

#chatbot-parent .sc-popup-window--search::-moz-placeholder {
	color: #c1c7cd;
}

#chatbot-parent .sc-popup-window--search::-ms-input-placeholder {
	color: #c1c7cd;
}

#chatbot-parent .sc-popup-window--search::placeholder {
	color: #c1c7cd;
}
