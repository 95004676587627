.dubaiChamberfeedbackSmilyContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 2px 0px 10px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
