#chatbot-parent .sc-user-input {
	max-height: 40px;
	margin: 0px;
	position: relative;
	bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	-webkit-transition: background-color 0.2s ease, -webkit-box-shadow 0.2s ease;
	transition: background-color 0.2s ease, -webkit-box-shadow 0.2s ease;
	-o-transition: background-color 0.2s ease, box-shadow 0.2s ease;
	transition: background-color 0.2s ease, box-shadow 0.2s ease;
	transition: background-color 0.2s ease, box-shadow 0.2s ease, -webkit-box-shadow 0.2s ease;
	height: 8%;
	max-width: 100% !important;
	display: flex !important;
	margin: 1em;
	border: 1px solid;
	border-radius: 40px;
}

#chatbot-parent .sc-user-input--text {
	width: 80%;
	resize: none;
	border: none;
	outline: none;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.33;
	white-space: pre-wrap;
	word-wrap: break-word;
	color: #565867;
	-webkit-font-smoothing: antialiased;
	max-height: 200px;
	overflow: clip;
	bottom: 0;
	overflow-x: clip;
	overflow-y: clip;
	background-color: #fff;
	-webkit-box-flex: 0 !important;
	    -ms-flex: 0 0 calc(100% - 120px) !important;
	        flex: 0 0 calc(100% - 120px) !important;
	border-radius: 40px;
	padding: 0.5em;
}

#chatbot-parent .sc-user-input--text:empty:before {
	content: attr(placeholder);
	display: block; /* For Firefox */
	color: rgba(86, 88, 103, 0.3);
	outline: none;
}

#chatbot-parent .sc-user-input--buttons {
	width: 100px;
	position: absolute;
	right: 10px;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

#chatbot-parent .sc-user-input--button:first-of-type {
	width: 70px;
}

#chatbot-parent .sc-user-input--button {
	width: 20%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-column-gap: 1em;
	   -moz-column-gap: 1em;
	        column-gap: 1em;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin: 0 10px;
}

#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

#chatbot-parent .sc-user-input--button button {
	cursor: pointer;
}

#chatbot-parent .sc-user-input--buttons input[type='file'] {
	display: none;
}

#chatbot-parent .sc-user-input--picker-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

#chatbot-parent .sc-user-input.active {
	-webkit-box-shadow: none;
	        box-shadow: none;
	background-color: white;
	-webkit-box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
	        box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}

#chatbot-parent .sc-user-input--file-icon,
#chatbot-parent .sc-user-input--send-icon {
	height: 20px;
	width: 20px;
	cursor: pointer;
	-ms-flex-item-align: center;
	    align-self: center;
	outline: none;
}

#chatbot-parent .sc-user-input--file-icon path,
#chatbot-parent .sc-user-input--send-icon path {
	fill: rgba(86, 88, 103, 0.3);
}

#chatbot-parent .sc-user-input--file-icon:hover path,
#chatbot-parent .sc-user-input--send-icon:hover path {
	fill: rgba(86, 88, 103, 1);
}

#chatbot-parent .sc-user-input--emoji-icon-wrapper,
#chatbot-parent .sc-user-input--send-icon-wrapper,
#chatbot-parent .sc-user-input--file-icon-wrapper {
	background: none;
	border: none;
	padding: 2px;
	margin: 0px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

#chatbot-parent .sc-user-input--send-icon-wrapper,
#chatbot-parent .sc-user-input--file-icon-wrapper {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
}

#chatbot-parent .sc-user-input--emoji-icon-wrapper:focus {
	outline: none;
}

#chatbot-parent .sc-user-input--emoji-icon {
	height: 18px;
	cursor: pointer;
	-ms-flex-item-align: center;
	    align-self: center;
}

#chatbot-parent .sc-user-input--emoji-icon path,
#chatbot-parent .sc-user-input--emoji-icon circle {
	fill: rgba(86, 88, 103, 0.3);
}

#chatbot-parent .sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
#chatbot-parent .sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle,
#chatbot-parent .sc-user-input--emoji-icon.active path,
#chatbot-parent .sc-user-input--emoji-icon.active circle,
#chatbot-parent .sc-user-input--emoji-icon:hover path,
#chatbot-parent .sc-user-input--emoji-icon:hover circle {
	fill: rgba(86, 88, 103, 1);
}

#chatbot-parent .ant-btn[disabled],
#chatbot-parent .ant-btn[disabled]:hover,
#chatbot-parent .ant-btn[disabled]:focus,
#chatbot-parent .ant-btn[disabled]:active {
	background: #f5f5f5 !important;
}

#chatbot-parent .sc-user-input .anticon {
	color: black;
}

#chatbot-parent .fas,
#chatbot-parent .fa {
	cursor: pointer;
}

#chatbot-parent .fa-microphone {
	color: #14a76c;
}

#chatbot-parent .fa-microphone:hover {
	color: #0c6e47;
}
