#chatbot-parent .sc-chat-window {
	width: 370px;
	height: calc(100% - 120px);
	max-height: 590px;
	position: fixed;
	right: 25px;
	bottom: 25px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
	        box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
	background: white;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	border-radius: 10px;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

#chatbot-parent .sc-chat-window.closed {
	opacity: 0;
	visibility: hidden;
	bottom: 90px;
}

#chatbot-parent .sc-message-list {
	height: calc(100% - 150px) !important;
	overflow-y: auto;
	background-color: white;
	background-size: 100%;
	padding: 40px 20px;
}

#chatbot-parent .sc-message--me {
	text-align: right;
}
#chatbot-parent .sc-message--them {
	text-align: left;
}

#chatbot-parent .chatBotTooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.chatBotTooltip {
	position: relative;
	display: inline-block;
}

.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}


#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}
/* Tooltip text */
#chatbot-parent .chatBotTooltip .chatBotTooltiptext,
.chatBotTooltip .chatbotTooltiptext {
	/* visibility: visible; */
	visibility: hidden !important;
	width: 210px;
	bottom: 100%;
	font-size: 15px;
	left: 0%;
	margin-left: -100px;
	/* width: 120px; */
	background-color: rgb(59, 59, 59);
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
#chatbot-parent .chatBotTooltip:hover .chatBotTooltiptext {
	visibility: visible;
}

#chatbot-parent .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	font-weight: 500;
}

@media (max-width: 450px) {
	#chatbot-parent .welcom-card {
		padding: 2em 1em !important;
	}

	#chatbot-parent .sc-chat-window {
		width: 100%;
		height: 100%;
		max-height: 100%;
		right: 0px;
		bottom: 0px;
		border-radius: 0px;
	}
	#chatbot-parent .sc-chat-window {
		-webkit-transition: 0.1s ease-in-out;
		-o-transition: 0.1s ease-in-out;
		transition: 0.1s ease-in-out;
	}
	#chatbot-parent .sc-chat-window.closed {
		bottom: 0px;
	}

	#chatbot-parent .sc-message--content {
		max-width: 85% !important;
	}

	#chatbot-parent .received {
		max-width: 85% !important;
	}

	#chatbot-parent .sc-message--content.sent {
		max-width: 85% !important;
	}

	#chatbot-parent .display-buttons {
		min-height: 25px !important;
		min-width: 91px !important;
		margin-left: 5%;
	}

	#chatbot-parent .button-flex {
		width: 100% !important;
	}

	#chatbot-parent .ant-btn-round {
		padding: 4px 5px !important;
	}

	#chatbot-parent .recevie-message-wrapper {
		width: 100% !important;
	}

	#chatbot-parent .receive-message-text {
		font-size: 13px !important;
		max-width: 80% !important;
	}

	#chatbot-parent .send-message-text {
		font-size: 13px !important;
	}

	#chatbot-parent .chat-input {
		border-radius: 10px !important;
		min-height: 15px !important;
	}

	#chatbot-parent .ant-btn-round.ant-btn-lg {
		height: 32px !important;
		padding: 0 20px !important;
		font-size: 12px !important;
		border-radius: 20px;
	}
	#chatbot-parent .ant-input:placeholder-shown {
		height: 40px !important;
	}

	#chatbot-parent .welcom-message-wrappaer {
		width: 80% !important;
	}

	#chatbot-parent .sc-user-input--text {
		width: 70% !important;
	}
	#chatbot-parent .sc-message {
		max-width: 100% !important;
	}

	#chatbot-parent .header-title {
		font-size: 0.8em !important;
	}

	#chatbot-parent .sub-header {
		font-size: 0.7em !important;
	}
}

@media only screen and (max-width: 480px) {
	#chatbot-parent .sc-user-input--text {
		-webkit-box-flex: 0 !important;
		    -ms-flex: 0 0 65% !important;
		        flex: 0 0 65% !important;
	}

	#chatbot-parent .sc-chat-window {
		width: 100% !important;
	}

	#chatbot-parent .sc-header {
		border-radius: 0 !important;
		height: 8vh !important;
	}

	#chatbot-parent .sc-header--team-name {
		font-size: 1.2em !important;
	}

	#chatbot-parent .sc-message--content {
		max-width: 85% !important;
	}

	#chatbot-parent .received {
		max-width: 85% !important;
	}

	#chatbot-parent .display-buttons {
		min-height: 25px !important;
		min-width: 91px !important;
		margin-left: 5%;
	}

	#chatbot-parent .ant-space-align-center {
		gap: 20% !important;
	}

	#chatbot-parent .sc-message-list {
		height: calc(100% - 150px) !important;
	}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
	#chatbot-parent .sc-user-input--text {
		-webkit-box-flex: 0 !important;
		    -ms-flex: 0 0 65% !important;
		        flex: 0 0 65% !important;
	}

	#chatbot-parent .ant-space-align-center {
		gap: 45% !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	#chatbot-parent .sc-user-input--text {
		-webkit-box-flex: 0 !important;
		    -ms-flex: 0 0 78% !important;
		        flex: 0 0 78% !important;
	}

	#chatbot-parent .ant-space-align-center {
		gap: 45% !important;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
	#chatbot-parent .ant-space-align-center {
		gap: 45% !important;
	}
	#chatbot-parent .sc-user-input--text {
		-webkit-box-flex: 0 !important;
		    -ms-flex: 0 0 75% !important;
		        flex: 0 0 75% !important;
	}
}

#chatbot-parent .chatbot-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	width: 80%;
	-webkit-box-shadow: rgb(0 0 0 / 25%) 0px 4px 16px;
	        box-shadow: rgb(0 0 0 / 25%) 0px 4px 16px;
	/* border-radius: 15px; */
}

#chatbot-parent .chatbot-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 8vh !important;
	width: 100% !important;
	/* border-radius: 15px 15px 0px 0px; */
	background-color: rgb(0, 108, 255);
	color: #ffff;
}

#chatbot-parent .header-content {
	padding: 10px;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	row-gap: 1em;
	-webkit-column-gap: 1em;
	   -moz-column-gap: 1em;
	        column-gap: 1em;
}

#chatbot-parent .avatar-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

#chatbot-parent .chat-content {
	background: rgb(234, 238, 243);
	overflow: scroll;
	padding: 20px !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	row-gap: 2em;
	background-color: #ffff;
}

#chatbot-parent .text-wrapper {
	width: 100%;
	-ms-flex-item-align: end;
	    align-self: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: center;
	    align-content: center;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	background-color: #f4f7f9;
}

#chatbot-parent .input-wrapper {
	width: 100%;
	padding: 10px;
}

#chatbot-parent .chat-button {
	height: 35px;
	background: rgb(0, 108, 255);
	border: 1px solid black;
	border-radius: 20px;
	color: #ffff;
	border: 0px;
	font-size: 14px;
	font-weight: 600;
	padding: 0 15px;
	cursor: pointer;
}

#chatbot-parent .chat-input {
	width: 100%;
	border: 0;
	height: 30px;
	font-size: 16px;
	color: rgb(150, 155, 166);
	background-color: #f4f7f9;
}

#chatbot-parent .chat-input:focus {
	outline: 0px;
}

#chatbot-parent .button-wrapper {
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

#chatbot-parent .content {
	width: 100%;
	width: 100%;
	margin: auto;
}

#chatbot-parent .chat-buttons-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

#chatbot-parent .option-buttons {
	height: 32px;
	border-radius: 16px;
	border-color: rgb(0, 108, 255);
	color: rgb(0, 108, 255);
	background: rgb(255, 255, 255);
	border: 1px solid;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	max-width: 100%;
	margin: 4px;
	height: 32px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid;
	border-radius: 16px;
	padding: 0 16px;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	cursor: pointer;
}

#chatbot-parent .option-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	padding: 0 20px 30px;
	background: rgb(244 247 249);
	border-radius: 10px 10px 0 0;
}

#chatbot-parent .option-text-content {
	font-size: 16px;
	line-height: 20px;
	word-wrap: break-word;
	margin-top: 12px;
	color: rgb(0, 0, 0);
}

#chatbot-parent .options-wrapper {
	width: 240px;
	border-radius: 10px;
	border: 1px solid;
	border-color: rgb(219, 225, 232);
	background: rgb(255, 255, 255);
}

#chatbot-parent .select-option {
	font-weight: 600;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 45px;
	border-top: 1px solid;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 10px;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	transition: all 0.3s;
	cursor: pointer;
	background: rgb(244 247 249);
	border-top-color: rgb(219, 225, 232);
}
#chatbot-parent .select-option-text {
	white-space: nowrap;
	overflow: hidden !important;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	text-align: center;
	color: rgb(0, 108, 255);
	width: 100%;
}

#chatbot-parent .recevie-message-wrapper {
	margin-top: 10px;
}

#chatbot-parent .receive-message-text-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

#chatbot-parent .recevie-message-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-column-gap: 1em;
	   -moz-column-gap: 1em;
	        column-gap: 1em;
}

#chatbot-parent .recevie-message-header .avatar {
	vertical-align: middle;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	max-width: none !important;
}

#chatbot-parent .receive-message-title {
	font-size: 13px;
	color: rgb(24, 25, 25);
	padding: 2px 0 4px;
	font-weight: 500;
}

#chatbot-parent .receive-message-text {
	border-radius: 20px 20px 20px 20px;
	min-width: 85px;
	float: left;
	padding: 10px 15px;
	color: #52585d !important;
	background: #e3f5fc;
	font-size: 14px;
	line-break: anywhere;
	/* max-width: 75%;s */
}
#chatbot-parent .receive-message-footer-wrapper {
	padding: 6px 0;
}

#chatbot-parent .receive-message-footer-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	max-width: 100%;
	font-size: 10px !important;
	color: grey;
	direction: ltr;
}

#chatbot-parent .branding-footer {
	background-color: gray;
}

#chatbot-parent .inner-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	height: 92vh;
}

#chatbot-parent .info-tabs {
	width: 25%;
	height: 88vh;
	height: 100%;
}

#chatbot-parent .content-text {
	width: 80%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	border: 1px solid;
	border-color: rgb(219, 225, 232);
	border-left: 0px;
	border-top: 0px;
	border-bottom: 0px;
}

#chatbot-parent .header-buttons {
	margin-left: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	    align-self: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	width: 70px;
}

#chatbot-parent .close-button {
	border-radius: 50%;
	border: 0px;
	background-color: #346dff;
	color: #ffff;
	font-weight: 800;
	font-size: 20px;
	cursor: pointer;
}

#chatbot-parent .options-wrapper:last-child {
	border-radius: 0 10px !important;
}

#chatbot-parent .send-message-wrapper {
	margin-top: 10px;
}

#chatbot-parent .send-message-text-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

#chatbot-parent .send-message-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-column-gap: 1em;
	   -moz-column-gap: 1em;
	        column-gap: 1em;
}

#chatbot-parent .send-message-header .avatar {
	vertical-align: middle;
	width: 35px;
	height: 35px;
	border-radius: 50%;
}

#chatbot-parent .send-message-title {
	color: rgb(24, 25, 25);
	padding: 2px 0 4px;
	font-size: 13px;
	text-align: end;
	font-weight: 500;
}

#chatbot-parent .send-message-text {
	border-radius: 20px 20px 20px 20px;
	min-width: 50px;
	float: right;
	padding: 10px 15px;
	color: #fdfdfe;
	background: #a4343a !important;
	font-size: 14px;
}

#chatbot-parent .send-message-footer-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	/* max-width: 85%; */
	font-size: 10px;
	color: #fdfdfe;
	padding: 6px 0;
	direction: ltr !important;
}

#chatbot-parent .themed-button {
	background: #0a3b5b !important;
	border-color: #0b3c5b !important;
}

#chatbot-parent .font-16 {
	font-size: 16px !important;
}

#chatbot-parent .flex-display-row {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
}

#chatbot-parent .chat-modal {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	background-color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

#chatbot-parent .header-subtitle {
	font-size: 0.6em;
	/* color: var(--secondary); */
	color: hsl(210deg 10% 62%);
}
