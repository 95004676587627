#chatbot-parent .welcome-wrapper-main-mobile {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	row-gap: 4em;
	width: 100% !important;
	height: 100% !important;
	background-color: #fff !important;
	padding: 2em;
	background-image: url(semi-circle-left.svg), url(semi-circle-right.svg);
	background-repeat: no-repeat;
	background-position: left bottom, right top;
}

#chatbot-parent .welcome-logo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
