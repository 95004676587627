#chatbot-parent .sc-message {
	width: 300px;
	margin: auto;
	padding-bottom: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

#chatbot-parent .sc-message--content {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

#chatbot-parent .sc-message--content.sent {
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

#chatbot-parent .sc-message--content.sent .sc-message--avatar {
	display: none;
}

#chatbot-parent .sc-message--avatar {
	background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7684294781f93bc99ad394a0eb3249c5768c21390163c9f55ea8ef83a4.gif);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	min-width: 30px;
	min-height: 30px;
	border-radius: 50%;
	-ms-flex-item-align: center;
	    align-self: center;
	margin-right: 15px;
}

#chatbot-parent .sc-message--text {
	padding: 17px 20px;
	border-radius: 6px;
	font-weight: 300;
	font-size: 14px;
	line-height: 1.4;
	white-space: pre-wrap;
	-webkit-font-smoothing: subpixel-antialiased;
	word-wrap: break-word;
	width: calc(100% - 90px);
}

#chatbot-parent .sc-message--content.sent .sc-message--text {
	color: white;
	background-color: #4e8cff;
	max-width: calc(100% - 120px);
	word-wrap: break-word;
}

#chatbot-parent .sc-message--content.received .sc-message--text {
	color: #263238;
	background-color: #f4f7f9;
	margin-right: 40px;
}

#chatbot-parent .sc-message--emoji {
	font-size: 40px;
}

#chatbot-parent .imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

.imagesOfchatIcon {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center !important;
	    -ms-flex-pack: center !important;
	        justify-content: center !important;
	-webkit-box-align: center !important;
	    -ms-flex-align: center !important;
	        align-items: center !important;
	grid-area: 2px !important;
}

#chatbot-parent .sc-launcher-bottom {
	background-color: white !important;
	position: fixed;
	right: 44px;
	bottom: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 150px !important;
	/* border-radius: 5px; */
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	-webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	/* padding: 0.3em; */
	/* border-radius: 7px; */
	/* border: 1px solid #A4343a !important; */
}

#chatbot-parent .sc-message--file {
	background: white;
	border: solid 1px #cccdd1;
	padding: 15px 20px;
	border-radius: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 300;
	font-size: 14px;
	line-height: 1.4;
	cursor: pointer;
	text-decoration: none;
}

#chatbot-parent .sc-message--file p {
	margin: 0px 0px 0px 10px;
	color: rgba(86, 88, 103, 0.6);
}

#chatbot-parent .sc-message--file .sc-user-input--file-icon:hover path {
	fill: rgba(86, 88, 103, 0.3);
}
